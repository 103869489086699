import { Layout, Space, Divider, Tooltip, Dropdown, Modal } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import synccossDashboardFrame from '../../assets/images/synncoss-dashboard-frame.svg'
import dashboardWalletLogo from '../../assets/images/dashboard-wallet-logo.svg'
import notificationsBellIcon from '../../assets/images/notifications-bell-icon.svg'
import {
  accountCircleIcon,
  appLogoWhiteTheme,
  helpIcon,
  logoutIcon
} from '../../assets/svg'
import DropdownFilter from '../shared/DropdownFilter'
import ProjectApps from '../views/ProjectApps'
import { IconButton } from '@mui/material'
import { Apps } from '@mui/icons-material'
import AvatarGroupComponent from '../shared/AvatarGroupComponent'
import { useState, useEffect } from 'react'
import { getPaticularUserData } from '../../API/UserAPI'
import { emptyAppData } from '../../redux/appData'
import { logOut } from '../../redux/loginLogout'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

const { Header } = Layout

const DashboardHeader = () => {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [userPaticularData, setUserPaticularData] = useState(null)

  const getPaticularUserDataAPi = async () => {
    try {
      const res = await getPaticularUserData()
      const { data } = res
      setUserPaticularData(data)
    } catch (error) {
      console.log('error while setting user data', error)
    }
  }

  console.log('user particular data ', userPaticularData)

  useEffect(() => {
    getPaticularUserDataAPi()
  }, [])

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    logout()
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const logout = () => {
    dispatch(logOut())
    dispatch(emptyAppData())
    navigate('/')
  }

  const menuItems = [
    {
      label: (
        <div className='d-flex flex-column justify-content-start rounded-0 ms-2'>
          <span className='font-roboto fs-6 fw-semibold profile-name'>
            {userPaticularData?.firstName || ''}{' '}
            {userPaticularData?.lastName || ''}
          </span>
          <span className='font-roboto fs-12 fw-normal org-name'>
            {userPaticularData?.email || ''}
          </span>
        </div>
      ),
      icon: (
        <AvatarGroupComponent
          name={
            userPaticularData?.firstName && userPaticularData?.firstName !== ''
              ? `${userPaticularData?.firstName} ${userPaticularData?.lastName}`
              : `${userPaticularData?.email}`
          }
          imageUrl={userPaticularData?.imageUrl || ''}
          size={40}
        />
      ),
      key: '0'
    },
    {
      type: 'divider'
    },
    {
      label: (
        <div
          className='py-2'
          onClick={() => navigate('/dashboard/manage-accounts')}
        >
          {accountCircleIcon}
          <span className='ms-2 font-roboto fs-12 fw-normal '>
            Manage your account
          </span>
        </div>
      ),
      key: '1'
    },
    {
      label: (
        <div className='py-2'>
          {helpIcon}
          <span className='ms-2 font-roboto fs-12 fw-normal '>Get help</span>
        </div>
      ),
      key: '2'
    },
    {
      label: (
        <div onClick={showModal} className='py-2'>
          {logoutIcon}
          <span className='ms-2 font-roboto fs-12 fw-normal '>Logout</span>
        </div>
      ),
      key: '3'
    }
  ]

  return (
    <Header
      className='dashboard-header px-2 py-0'
      style={{
        height: 'auto',
        backgroundColor: '#FFFFFF !important'
      }}
    >
      <nav className='navbar navbar-expand-lg bg-body-tertiary dashboard-header-inner'>
        <div className='container-fluid d-flex justify-content-between'>
          <div className='collapse navbar-collapse' id='navbarTogglerDemo01'>
            <Link to='/' className='app-brand-logo'>
              {appLogoWhiteTheme}
            </Link>
            <div className='d-flex align-items-center ms-auto'>
              <Tooltip
                trigger={['click']}
                title={<ProjectApps />}
                className='project-apps-tooltip me-3'
                overlayClassName='project-apps-overlay'
                placement='bottomRight'
                color='#fff'
                arrow={false}
                overlayStyle={{
                  maxWidth: '20rem',
                  width: 'max-width'
                }}
              >
                <IconButton>
                  <Tooltip
                    color='rgba(0,0,0,0.5)'
                    overlayStyle={{
                      width: 'fit-content',
                      maxWidth: 'fit-content'
                    }}
                    title={<p className='mb-0'>Our Apps</p>}
                    overlayInnerStyle={{
                      width: 'fit-content',
                      maxWidth: 'fit-content'
                    }}
                    trigger={['hover']}
                  >
                    <Apps
                      sx={{
                        fontSize: '1.5rem',
                        color: '#555'
                      }}
                    />
                  </Tooltip>
                </IconButton>
              </Tooltip>
              <Dropdown
                menu={{ items: menuItems }}
                trigger={['click']}
                className='app-header-dropdown'
                style={{
                  //border: '2px solid #434343',
                  backgroundColor: 'red'
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <AvatarGroupComponent
                    size='large'
                    extraclass='ms-3'
                    name={
                      userPaticularData?.firstName &&
                      userPaticularData?.firstName !== ''
                        ? `${userPaticularData?.firstName} ${userPaticularData?.lastName}`
                        : `${userPaticularData?.email}`
                    }
                  />
                </a>
              </Dropdown>
            </div>
            <Modal
              open={isModalOpen}
              footer={null}
              closable={false}
              centered
              className='square-modal'
            >
              <div className='p-2 d-flex flex-column p-3'>
                <h3 className='confirm-logout-heading fw-bolder fs-24 font-roboto'>
                  Confirm Log Out
                </h3>
                <p className='confirm-logout-heading fs-16 font-roboto fw-normal'>
                  Logging out will terminate your current session and require
                  you to sign in again to access your account
                </p>
                <div className='d-flex justify-content-end align-items-center'>
                  <button
                    onClick={handleCancel}
                    className='cancel-btn font-roboto fs-14 fw-bolder py-2 px-4'
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleOk}
                    className='logout-btn font-roboto fs-14 fw-bolder ms-3 py-2 px-4'
                  >
                    Log out
                  </button>
                </div>
              </div>
            </Modal>
          </div>
          {/* <Space size='28px'>
            <div className='d-flex justify-content-center align-items-center me-5'>
              <img
                src={notificationsBellIcon}
                alt='notifications-icon'
                className='me-3'
              />
              <Divider type='vertical' className='divider-line' />
              <div
                className='d-flex justify-content-center align-items-center p-3 mx-3'
                style={{ height: '36px' }}
              >
                <img
                  src={synccossDashboardFrame}
                  alt='frame-logo'
                  width='36px'
                  height='36px'
                />
                <div className='d-flex flex-column ms-2'>
                  <h6
                    className='fs-13 fw-bolder m-0 font-montserrat'
                    style={{ color: '#000' }}
                  >
                    Cameron Williamson
                  </h6>
                  <h6
                    className='fs-12 m-0 font-montserrat font-semibold'
                    style={{ color: '#8E8E8E' }}
                  >
                    CrudCook
                  </h6>
                </div>
                <div className='p-3'>
                  <DropdownFilter />
                </div>
              </div>
              <Divider type='vertical' className='divider-line' />
              <img
                src={dashboardWalletLogo}
                alt='dashboard-wallet-lgoo'
                className='mx-3'
              />
            </div>
          </Space> */}
        </div>
      </nav>
    </Header>
  )
}

export default DashboardHeader
