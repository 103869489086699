import axios from 'axios'
import Cookies from 'js-cookie'

let instance = axios.create({
  baseURL: 'https://pms.synccos.com',
  timeout: 100000
})

console.log(process.env.REACT_APP_BASE_URL, 'REACT_APP_BASE_URL--')

instance.interceptors.request.use((config) => {
  let accessToken = Cookies.get('pms-access-token') ?? ''
  let refreshToken = Cookies.get('pms-refresh-token') ?? ''

  if (config?.url.includes('refresh-token')) {
    config.headers.Authorization = `Bearer ${refreshToken}`
  } else {
    // woirkign on it disabling bearer tokken for paticular urls
    if (
      !config?.url.includes('signup') &&
      !config?.url.includes('search') &&
      !config?.url.includes('signin')
    ) {
      console.log('hii')
      config.headers.Authorization = `Bearer ${accessToken}`
    }
  }

  return config
})

const getRequest = (API, body) => instance.get(API)

const postRequest = (API, body) => instance.post(API, body)

const putRequest = (API, body) => instance.put(API, body)

const deleteRequest = (API, body) => instance.delete(API, body)

export { getRequest, postRequest, instance, putRequest, deleteRequest }
