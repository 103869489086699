import { configureStore } from '@reduxjs/toolkit'
import loginLogoutReducer from './loginLogout'
import appDataReducer from './appData'

const rootReducer = {
  loginLogout: loginLogoutReducer,
  appData: appDataReducer
}

export default configureStore({
  reducer: rootReducer,
  devTools: true
})
