import React from 'react'
import { Routes, Route } from 'react-router'
import DashboardLayout from '../components/Layout/DashboardLayout'
import Dashboard from '../pages/Dashboard'
import MyChecks from '../pages/MyChecks'
import CreateCheck from '../pages/CreateCheck'
import CreateRegularCheck from '../components/views/CreateRegularCheck/CreateRegularCheck'
import CreateMultipleChecks from '../components/views/CreateMultipleChecks/CreateMultipleChecks'
import RegularChecksForm from '../components/views/CreateRegularCheck/RegularCheck/RegularChecksForm'
import RecurringChecksForm from '../components/views/CreateRegularCheck/RecurringChecks/RecurringChecksForm'
import BlankChecksForms from '../components/views/CreateRegularCheck/BlankCheck/BlankChecksForms'
import BlankCheckForm from '../components/views/CreateMultipleChecks/BlankChecks/BlankCheckForm'
import RegularCheckForm from '../components/views/CreateMultipleChecks/RegularChecks/RegularCheckForm'
import RecurringCheckForm from '../components/views/CreateMultipleChecks/RecurringChecks/RecurringCheckForm'
import ImportChecks from '../pages/ImportChecks'
import ImportCheckInstructions from '../pages/ImportCheckInstructions'
import ImportCheckUpload from '../pages/ImportCheckUpload'
import ValidateImportData from '../pages/ValidateImportData'
import MyChecksTable from '../components/views/Checks/MyChecksTable'
import MailedChecksTable from '../components/views/Checks/MailedChecksTable'
import RecurringChecksTable from '../components/views/Checks/RecurringChecksTable'
import ImportedChecksTable from '../components/views/Checks/ImportedChecksTable'
import ExportChecks from '../components/shared/Drawers/ExportChecks'
import Invoices from '../pages/Invoices'
import Payments from '../pages/Payments'
import Home from '../pages/Home'
import AuthLayout from '../components/Layout/AuthLayout'
import Login from '../pages/Login'
import Signup from '../pages/Signup'
import ForgetPassword from '../pages/ForgetPassword'
import NewPassword from '../pages/NewPassword'
import CreateProfile from '../pages/CreateProfile'
import PrivateRoute from './PrivateRoute'
import SignUpWithInvite from '../pages/SignUpWithInvite'
import Company from '../pages/Company'
import ManageTags from '../pages/ManageTags'

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/auth' element={<AuthLayout />}>
        <Route exact path='/auth/login' element={<Login />} />
        <Route exact path='/auth/signup' element={<Signup />} />
        <Route exact path='/auth/invite' element={<SignUpWithInvite />} />
        <Route exact path='/auth/company' element={<Company />} />
        <Route
          exact
          path='/auth/forget-password'
          element={<ForgetPassword />}
        />
        <Route exact path='/auth/reset-password' element={<NewPassword />} />
        {/* <Route exact path="/auth/verify-otp" element={<OTPVerify />} /> */}
        <Route exact path='/auth/create-profile' element={<CreateProfile />} />
      </Route>
      {/* <Route path='/' element={<PrivateRoute />}>
        <Route exact path='/dashboard' element={<DashboardLayout />}>
          <Route path='manage-tags' element={<ManageTags />} />
          <Route path='main' element={<Dashboard />} />
          <Route exact path='my-checks' element={<MyChecks />}>
            <Route path='all-checks' element={<MyChecksTable />} />
            <Route path='mailed-checks' element={<MailedChecksTable />} />
            <Route path='recurring-checks' element={<RecurringChecksTable />} />
            <Route path='imported-checks' element={<ImportedChecksTable />} />
          </Route>
          <Route path='my-checks/import-checks' element={<ImportChecks />}>
            <Route path='instructions' element={<ImportCheckInstructions />} />
            <Route path='upload-file' element={<ImportCheckUpload />} />
            <Route path='validate-data' element={<ValidateImportData />} />
          </Route>
          <Route path='create-check' element={<CreateCheck />}>
            <Route
              exact
              path='create-regular-check'
              element={<CreateRegularCheck />}
            >
              <Route path='regular' element={<RegularChecksForm />} />
              <Route path='recurring' element={<RecurringChecksForm />} />
              <Route path='blank' element={<BlankChecksForms />} />
            </Route>
            <Route
              exact
              path='create-multiple-check'
              element={<CreateMultipleChecks />}
            >
              <Route path='regular' element={<RegularCheckForm />} />
              <Route path='recurring' element={<RecurringCheckForm />} />
              <Route path='blank' element={<BlankCheckForm />} />
            </Route>
            <Route exact path='export-checks' element={<ExportChecks />}>
              <Route path='analytics' />
              <Route path='regular' />
            </Route>
          </Route>
          <Route exact path='invoices' element={<Invoices />}></Route>
          <Route exact path='payments' element={<Payments />}></Route>
        </Route>
      </Route> */}
    </Routes>
  )
}

export default AppRoutes
