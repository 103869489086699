import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormHeader from '../components/shared/forms/FormHeader'
import FormComponent from '../components/shared/forms'
import { Button, Checkbox, message } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import ButtonComponent from '../components/shared/ButtonComponent'
import SocialLogin from '../components/shared/SocialLogin'
import { signUpAPI } from '../API/authAPI'
import { sendOTP } from '../API/UserAPI'
import appLogoLight from '../assets/images/app-logo-light.png'
import Cookies from 'js-cookie'
import { splitName } from '../utils/helper'

const Signup = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()

  const initialValues = {
    name: '',
    // lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    //phone: '',
    isAggred: false
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Required!'),
    // lastName: Yup.string().required('Required!'),
    email: Yup.string().email('Incorrect Email format').required('Required!'),
    password: Yup.string()
      .required('Required!')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,16}$/,
        'Password must contain at least one letter, one number and one special character'
      ),
    confirmPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,16}$/,
        'Password must contain at least one letter, one number and one special character'
      ),
    isAggred: Yup.boolean()
  })

  const onSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      message.warning('Password Mismatch.')
      return false
    }

    let body = { ...values }

    delete body.isAggred

    const nameSplit = splitName(body?.name)

    body.firstName = nameSplit?.firstName || ''
    body.lastName = nameSplit?.lastName || ''
    delete body?.name

    try {
      const res = await signUpAPI(body)
      const { data } = res

      Cookies.set('pms-access-token', data?.accessToken, {
        expires: 1 / 24
      })

      Cookies.set('pms-refresh-token', data?.refreshToken, {
        expires: 12 / 24
      })

      Cookies.set('user-email', body?.email, {
        expires: 1 / 24
      })

      Cookies.set('check-refresh-token', data.refreshTokenCheck, {
        expires: 12 / 24
      })
      Cookies.set('check-access-token', data.accessTokenCheck, {
        expires: 1 / 24
      })

      // need to test userId data
      if (data?.userId) {
        Cookies.set('userId', data?.userId, {
          expires: 1 / 24
        })
      }
      navigate('/auth/company')
    } catch (error) {
      messageApi.error('Not able to send OTP!')
    }
  }

  return (
    <>
      {contextHolder}
      <div className='auth-form-div'>
        <FormHeader
          text='All your needs met in one convenient place.'
          head='Sign Up for free'
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, handleSubmit }) => {
            const { isAggred } = values
            return (
              <Form>
                <FormComponent
                  control='input'
                  name='name'
                  label={
                    <>
                      Name <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Enter your Name'
                  type='text'
                />
                {/* <FormComponent
									control="input"
									name="lastName"
									label={
										<>
											Last Name <span className="required-field-star">*</span>
										</>
									}
									required
									placeholder="Last Name"
									type="text"
								/> */}
                <FormComponent
                  control='input'
                  name='email'
                  label={
                    <>
                      Email <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Enter your Email ID'
                  type='email'
                />
                <FormComponent
                  control='input'
                  name='password'
                  label={
                    <>
                      Password <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Enter your Password'
                  type='password'
                />
                <FormComponent
                  control='input'
                  name='confirmPassword'
                  label={
                    <>
                      Confirm Password{' '}
                      <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Re-Enter your Password'
                  type='password'
                />
                {/* <FormComponent
									name="phone"
									label={
										<>
											Phone Number{' '}
											<span className="required-field-star">*</span>
										</>
									}
									control="phone-input"
									type="text"
									country="us"
									onChange={(phoneNumber) =>
										setFieldValue('phone', phoneNumber)
									}
								/> */}

                <div className='aggree-div d-flex align-items-center fs-12'>
                  <Checkbox
                    className='generic-checkbox me-2 mb-4'
                    name='isAggred'
                    onChange={(e) => {
                      setFieldValue('isAggred', e.target.checked)
                    }}
                    checked={values.isAggred}
                    required
                  />
                  <p style={{ color: '#9797A6', fontWeight: 400 }}>
                    By signing up you acknowledge that you have read and agree
                    to our{' '}
                    <span style={{ color: '#555555', fonteight: 500 }}>
                      Terms of Service
                    </span>{' '}
                    and our{' '}
                    <span style={{ color: '#555555', fonteight: 500 }}>
                      Privacy Policy
                    </span>{' '}
                  </p>

                  {/* <Link to="/terms" className="text-decoration-none">
										Terms and Conditions
									</Link> */}
                </div>
                <ButtonComponent
                  type='submit'
                  text='Sign Up'
                  variant='dark'
                  extraclassName='mt-4 mb-0 btn-color w-100'
                  disabled={!isAggred}
                  click={handleSubmit}
                />
              </Form>
            )
          }}
        </Formik>
        <div className='divider mt-4 mb-4 d-flex align-items-center justify-content-center'>
          <span className='line'></span>
          <span className='txt'>Or Sign up with</span>
          <span className='line'></span>
        </div>

        <SocialLogin text='up' />
        <p className='redirect-txt text-center mt-3'>
          Already have an account ? <Link to='/auth/login'>Sign In</Link>
        </p>
      </div>
    </>
  )
}

export default Signup
