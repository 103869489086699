import { createSlice } from '@reduxjs/toolkit'

const appData = createSlice({
  name: 'appData',
  initialState: {
    initalMappingData: { data: {}, isLoading: true },
    mappingData: { data: {}, isLoading: true }
  },
  reducers: {
    updateInitialMappingData: (state, action) => {
      const { payload } = action

      return {
        ...state,
        initalMappingData: {
          data: { ...payload },
          isLoading: false
        }
      }
    },

    updateMappingData: (state, action) => {
      const { payload } = action

      return {
        ...state,
        mappingData: {
          data: { ...payload },
          isLoading: false
        }
      }
    },

    emptyAppData: (state) => {
      return {
        ...state,
        mappingData: { data: {}, isLoading: true },
        initalMappingData: { data: {}, isLoading: true }
      }
    }
  }
})

export const { updateMappingData, emptyAppData, updateInitialMappingData } =
  appData.actions

export default appData.reducer
