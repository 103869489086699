import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'

import FormHeader from '../components/shared/forms/FormHeader'
import FormComponent from '../components/shared/forms/index'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import { login } from '../redux/loginLogout'
import {
  appleSignUp,
  signUpGoogle,
  synccosSignUpGoogle
} from '../API/socialAPI'
import ButtonComponent from '../components/shared/ButtonComponent'
import { Checkbox } from 'antd'
import { message } from 'antd'
import { checkIntegration } from '../utils/helper'

import dayjs from 'dayjs'

const CreateProfile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [loginType, setLoginType] = useState(null)
  const [authData, setAuthData] = useState(null)
  const [formValues, setFormValues] = useState(null)
  const [initialValues, setInitialValues] = useState(null)

  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    if (location?.state) {
      setAuthData(location?.state?.authData)
      getUserData(location?.state?.userData)
      setLoginType(location?.state?.loginType)
    } else {
      navigate('/auth/signup')
    }
  }, [location])

  const getUserData = (data) => {
    setFormValues({
      email: data?.email || '',
      name: `${data?.firstName || ''} ${data?.middleName || ''} ${
        data?.lastName || ''
      }`,
      // middleName: data?.middleName || '',
      // lastName: data?.lastName || '',
      isAggreed: false,
      phone: data?.phone || '',
      dateOfBirth:
        data?.dateOfBirth && data?.dateOfBirth !== null
          ? dayjs(data?.dateOfBirth)
          : dayjs(new Date())
    })
    setInitialValues({
      email: data?.email || ''
    })
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Incorrect Email').required('Required!'),
    name: Yup.string().required('Required!'),
    // lastName: Yup.string().required('Required!'),
    // middleName: Yup.string(),
    phone: Yup.string(),
    dateOfBirth: Yup.date()
  })

  const onSubmit = async (values) => {
    const { name, email, phone, dateOfBirth, ...rest } = values
    if (initialValues?.email !== email) {
      messageApi.error('User email not verified.')
      return false
    }

    const body = { name, email, phone, dateOfBirth, ...authData }
    delete body.isAggreed
    if (loginType === 'google') {
      signUpSocial(body)
    } else if (loginType === 'apple') {
      try {
        const res = appleSignUp(body)
        const { data } = res

        Cookies.set('pms-refresh-token', authData.refreshToken, {
          expires: 12 / 24
        })
        Cookies.set('user-status', true, {
          expires: 12 / 24
        })
        Cookies.set('pms-access-token', authData.accessToken, {
          expires: 1 / 24
        })
        dispatch(login({ token: authData.accessToken }))
        messageApi.error('Login successful')

        // checkIntegration(navigate, messageApi)
      } catch (error) {
        messageApi.error('Login unsuccessful')
      }
    }
  }

  const signUpSocial = async (body) => {
    try {
      const res = await signUpGoogle(body)
      const { data } = res

      Cookies.set('pms-refresh-token', data?.refreshToken, {
        expires: 12 / 24
      })
      Cookies.set('pms-access-token', data?.accessToken, {
        expires: 1 / 24
      })
      Cookies.set('user-email', body?.email, {
        expires: 12 / 24
      })
      message.success('Profile created successfully.')

      navigate('/auth/company')
    } catch (error) {
      message.error('Unable to signup.')
    }
  }

  return (
    <>
      {contextHolder}
      <div className='auth-form-div'>
        <FormHeader
          head='Create Profile'
          text='Create checkwriter account with your google account details.'
        />
        {formValues ? (
          <Formik
            initialValues={formValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => {
              return (
                <Form>
                  <FormComponent
                    name='name'
                    type='text'
                    label='Name'
                    control='input'
                    required
                  />
                  <FormComponent
                    name='email'
                    type='email'
                    label='Email'
                    control='input'
                    disabled
                  />

                  <FormComponent
                    name='phone'
                    label='Phone Number'
                    control='phone-input'
                    type='text'
                    country='us'
                    onChange={(phoneNumber) =>
                      setFieldValue('phone', phoneNumber)
                    }
                  />

                  <FormComponent
                    control='date-picker'
                    name='callDate'
                    label={<>Date of Birth (optional)</>}
                    placeholder='Enter date of birth'
                  />

                  <div className='d-flex align-items-start justify-content-center mb-3'>
                    <span className='me-1 mt-0'>
                      <Checkbox
                        className='p-1 pt-0'
                        checked={values.isAggreed}
                        defaultChecked={true}
                        required
                        size='small'
                        name='isAggreed'
                        onChange={(e) => {
                          setFieldValue('isAggreed', e.target.checked)
                        }}
                      />
                    </span>
                    <p className='redirect-txt text-left mt-0 mb-3 '>
                      By clicking here, I state that I have read and understood
                      the
                      <Link to='/terms'> terms and conditions. </Link>
                    </p>
                  </div>

                  <ButtonComponent
                    type='submit'
                    text='Continue'
                    extraclassName='mt-5 w-100'
                    variant='dark'
                    click={handleSubmit}
                    disabled={!values.isAggreed}
                  />
                </Form>
              )
            }}
          </Formik>
        ) : null}
      </div>
    </>
  )
}

export default CreateProfile

//// google code
// try {
// 	const resp = await signUpGoogle(bo)

// 	const { data } = resp

// 	history.push({
// 		pathname: '/auth/create-profile',
// 		state: {
// 			authData: {
// 				...data
// 			}
// 		}
// 	})
// } catch (err) {
// 	debugger
// }
