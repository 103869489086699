import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormHeader from '../components/shared/forms/FormHeader'
import FormComponents from '../components/shared/forms/index'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ButtonComponent from '../components/shared/ButtonComponent'
import { message } from 'antd'
import { resetUserPassword, verifyForgetToken } from '../API/authAPI'

const NewPassword = () => {
  const navigate = useNavigate()

  const location = useLocation()

  const [messageApi, contextHolder] = message.useMessage()
  const [userId, setUserId] = useState('')

  const checkToken = async () => {
    const searchParams = new URLSearchParams(location?.search)
    const token = searchParams?.get('token') ? searchParams.get('token') : ''
    try {
      const res = await verifyForgetToken(token)
      const { data } = res
      setUserId(data?.userId || '')
    } catch (error) {
      debugger
      messageApi.error('Something went wrong.')
      navigate('/auth/login')
    }
  }

  useEffect(() => {
    if (!location.search) {
      navigate('/auth/login')
    } else {
      checkToken()
    }
  }, [])

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!^%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Password must contain at least one letter, one number and one special character'
      ),
    confirmPassword: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!^%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
        'Password must contain at least one letter, one number and one special character'
      )
  })

  const initialValues = {
    confirmPassword: '',
    password: ''
  }

  const onSubmit = async (values) => {
    if (values.password !== values.confirmPassword) {
      ///Todo:- Add a message
      // dispatch(
      // 	updateSnackbar({
      // 		open: true,
      // 		severity: 'error',
      // 		message: 'Password mismatch.'
      // 	})
      // )
      return false
    }

    try {
      const response = await resetUserPassword({
        ...values,
        userId: userId
      })
      messageApi.success('Password updated successfully!')
      navigate('/auth/login')
    } catch (error) {
      messageApi.error('Not able to update the password!')
    }
  }

  return (
    <>
      {contextHolder}
      <div className='auth-form-div'>
        <div className='pt-5'>
          <FormHeader
            head='Set new password'
            text='Must be atleast 8 characters'
          />
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormComponents
                name='password'
                label='New Password'
                control='input'
                type='password'
              />
              <FormComponents
                name='confirmPassword'
                label='Confirm Password'
                control='input'
                type='password'
              />
              <ButtonComponent
                type='submit'
                text='Reset Password'
                extraclassName='mt-5 w-100'
                variant='dark'
                click={handleSubmit}
              />
            </Form>
          )}
        </Formik>

        <p className='redirect-txt text-center mt-5'>
          Back to <Link to='/auth/login'>Log In</Link>
        </p>
      </div>
    </>
  )
}

export default NewPassword
