import React, { useState } from 'react'
import { Select, Tag } from 'antd'
import { ErrorMessage, Field } from 'formik'
import TextError from '../../../hoc/TextError'
import FormComponent from '.'
import './forms.css'

const { Option } = Select

const CustomSelectComponent = ({
  options,
  placeholder,
  name,
  multiple = false,
  field,
  form,
  id,
  ...rest
}) => {
  const [otherInputValue, setOtherInputValue] = useState('')

  const renderSelectedValues = () => {
    if (multiple && field.value.length > 0) {
      return (
        <div
          className='selected-values mt-2'
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '16px'
          }}
        >
          {field.value.map((value) => (
            <Tag
              style={{
                padding: '12px',
                border: '1px solid #D9D9D9',
                fontSize: '12px',
                fontFamily: 'Roboto',
                backgroundColor: '#FFF',
                borderRadius: 0
              }}
              key={value}
              closable
              onClose={() => {
                // Handle deselect when using controlled component
                const updatedSelectedValues = field.value.filter(
                  (val) => val !== value
                )
                form.setFieldValue(field.name, updatedSelectedValues)
              }}
            >
              <img
                src={options.find((option) => option.id === value)?.avatarUrl}
                alt={value}
                style={{ width: '20px', marginRight: '8px' }}
              />
              {options.find((option) => option.id === value)?.name}
            </Tag>
          ))}
        </div>
      )
    }
    return null
  }

  return (
    <>
      <Select
        placeholder={placeholder}
        mode={multiple ? 'multiple' : 'default'}
        className='custom-select'
        onChange={(value) => {
          if (value === 'Others') {
            setOtherInputValue(value)
            form.setFieldValue(field.name, value)
          } else {
            setOtherInputValue('')
            form.setFieldValue(field.name, value)
          }
        }}
        value={field.value || otherInputValue}
      >
        {options.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.avatarUrl ? (
              <img
                src={option.avatarUrl}
                alt={option.id}
                style={{ width: '20px', height: '20px', marginRight: '8px' }}
              />
            ) : (
              ''
            )}
            {option.name}
          </Option>
        ))}
      </Select>
      {renderSelectedValues()}
    </>
  )
}

const SelectComponent = ({
  name,
  label,
  options,
  multiple,
  placeholder,
  extraName,
  formfieldClass = '',
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState([])
  return (
    <div className={`formField ${formfieldClass}`}>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <Select
              component={CustomSelectComponent}
              id={extraName}
              name={name}
              {...rest}
              multiple={multiple}
              options={options}
              label={label}
              placeholder={placeholder}
              mode={multiple ? 'multiple' : undefined}
              onChange={(e) => {
                console.log(e, '---e')
                setFieldValue(name, e)
              }}
            />
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default SelectComponent

// if we are selecting "Others" option the value should be Others but there should be one more key added to the payload with extraRole or extraCompanyIndustry
