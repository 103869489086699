import React from 'react'
import Header from './Header'
import Footer from './Footer'
import { useLocation } from 'react-router'

const AppLayout = (props: any) => {
  const location = useLocation()
  console.log('asjkhdallsjf', location)

  return (
    <>
      {location?.pathname.includes('auth') ||
      location.pathname?.includes('/dashboard') ? null : (
        <Header />
      )}

      <div className='main-container'>{props.children}</div>
      {location?.pathname.includes('auth') ||
      location.pathname?.includes('/dashboard') ? null : (
        <Footer />
      )}
    </>
  )
}

export default AppLayout
