import Cookies from 'js-cookie'
import { deleteRequest, getRequest, postRequest, putRequest } from '.'

const getAllMembers = async () => {
  const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
  const res = await getRequest(
    `/v1/member?companyDetailsId=${companyDetailsId}`
  )
  return res
}

const getMember = async (id) => {
  const res = await getRequest(`/v1/member/${id}`)
  return res
}

const editMember = async (options) => {
  const res = await putRequest(`/v1/member`, options)
  return res
}

const deleteMembers = async (ids) => {
  const res = await deleteRequest(`/v1/member/multiple?ids=${ids}`)
  return res
}

const createMember = async (token) => {
  const res = await postRequest(`/v1/member/token-validity?token=${token}`)
  return res
}
export const bulkCreateMember = async (body) => {
  const res = await postRequest(`/v1/member/add`, body)
  return res
}
export const removeMember = async (body) => {
  const res = await postRequest(`/v1/member/remove`, body)
  return res
}

const inviteMembers = async (body) => {
  // const email = Cookies.get('user-email') ?? ''
  const res = await postRequest(`/v1/member/multiple`, body)
  return res
}

const getUserProfile = async (hash) => {
  const res = await getRequest(`/v1/custom/avatar?hash=${hash}`)
  return res
}

const resendInvite = async (email) => {
  const companyDetailsId = Cookies.get('companyDetailsId') ?? ''
  const res = await postRequest(
    `/v1/member/invite-link?companyDetailsId=${companyDetailsId}&email=${email}`
  )
  return res
}

export {
  getAllMembers,
  editMember,
  deleteMembers,
  getMember,
  createMember,
  inviteMembers,
  getUserProfile,
  resendInvite
}
