import axios from 'axios'
import { getRequest, postRequest } from '.'
import Cookies from 'js-cookie'

import { splitName } from '../utils/helper'

const getGoogleInfo = async (token) => {
  axios
    .get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${token}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json'
        }
      }
    )
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

const signUpGoogle = async (body) => {
  let obj = { ...body }
  const { firstName, lastName } = splitName(obj?.name)
  obj['firstName'] = firstName
  obj['lastName'] = lastName
  const res = await postRequest('/v1/auth/google/signup', obj)

  // if (res?.status === 200) {
  // 	const resSyn = await synccosSignUpGoogle(body)
  // 	// if(res?.status)
  // }
  return res
}

const signInGoogle = async (body) => {
  const res = await postRequest('/v1/auth/google/signin', body)
  return res
}

const synccosSignUpGoogle = async (body) => {
  const res = await axios.post(
    'https://synccos-main-api.synccos.com/central-auth/auth/google/signup',
    body
  )
  return res
}

const appleSignUp = async (body) => {
  const res = await postRequest('central-auth/auth/apple/signup', body)

  return res
}

const appleSignIn = async (body) => {
  const res = await postRequest('/central-auth/auth/apple/login', body)
  return res
}

export {
  getGoogleInfo,
  signUpGoogle,
  appleSignUp,
  appleSignIn,
  signInGoogle,
  synccosSignUpGoogle
}
