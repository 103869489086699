import { Field, ErrorMessage } from 'formik'
import PhoneInput from 'react-phone-input-2'

import TextError from '../../../hoc/TextError'

const PhoneNumberInput = (props) => {
  const { name, label, ...rest } = props

  return (
    <div className='formField'>
      <label htmlFor={name}>{label}</label>
      <Field
        id={name}
        name={name}
        render={({ field, form }) => {
          const { setFieldValue } = form
          return (
            <PhoneInput
              {...rest}
              label={label}
              className='phone-input'
              value={field.value}
              onChange={(phone) => setFieldValue(name, phone)}
            />
          )
        }}
        label={label}
      />
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default PhoneNumberInput
