import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect } from 'react'
import './App.css'
import './styles/Forms.css'
import './styles/ExtraStyles.css'
import './styles/ComponentsStyles.css'
import AppLayout from './components/Layout/AppLayout'
import 'react-phone-input-2/lib/style.css'
import AppRoutes from './routes'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

const App = () => {
  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (
      location?.search &&
      location?.search !== '' &&
      location?.search.includes('tkn')
    ) {
      const params = new URLSearchParams(location?.search)
      location.search = ''
      let token = params.get('tkn', { domain: 'projects.synccos.com' })
      let companyDetailsId = params.get('companyDetailsId', {
        domain: 'projects.synccos.com'
      })
      let email = params.get('mail', { domain: 'projects.synccos.com' })
      let refreshtoken = params.get('rfTkn', {
        domain: 'projects.synccos.com'
      })

      let pmsAccesstkn = params.get('pmsTkn', {
        domain: 'projects.synccos.com'
      })
      let pmsRefreshToken = params.get('pmsRfTkn', {
        domain: 'projects.synccos.com'
      })

      if (token && token !== '') {
        Cookies.set('check-access-token', token, {
          expires: 1 / 24
        })
        Cookies.set('check-refresh-token', refreshtoken, {
          expires: 12 / 24
        })
        Cookies.set('pms-access-token', token, {
          expires: 1 / 24
        })
        Cookies.set('pms-refresh-token', refreshtoken, {
          expires: 12 / 24
        })
        Cookies.set('user-status', true, {
          expires: 12 / 24
        })

        Cookies.set('user-email', email, {
          expires: 12 / 24
        })

        Cookies.set('companyDetailsId', companyDetailsId, {
          expires: 12 / 24
        })
        // navigate('/dashboard/main')
        window.open(
          `https://synccoscheck.com?tkn=${token}&cmpId=${companyDetailsId}&mail=${email}&rfTkn=${refreshtoken}&rfTkn=${refreshtoken}&pmsTkn=${pmsAccesstkn}&pmsRfTkn=${pmsRefreshToken}`,
          "_self",
        );
      }
    } else if (
      location?.search &&
      location?.search !== '' &&
      location?.search.includes('out')
    ) {
      Cookies.remove('check-access-token')
      Cookies.remove('companyDetailsId')
      Cookies.remove('user-email')
      Cookies.remove('user-status')
      Cookies.remove('check-refresh-token')
    }
  }, [location?.search])

  return (
    <GoogleOAuthProvider
      clientId={`${
        process.env?.REACT_APP_GOOGLE_CLIENT_ID ||
        '787275588098-tjg5gnbta3u0k0vlktko61sapfq2ki1e.apps.googleusercontent.com'
      }`}
    >
      <AppLayout>
        <AppRoutes />
      </AppLayout>
    </GoogleOAuthProvider>
  )
}

export default App
