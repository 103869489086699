import React from 'react'
import FormHeader from '../components/shared/forms/FormHeader'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import FormComponent from '../components/shared/forms'
import { Checkbox, message } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ButtonComponent from '../components/shared/ButtonComponent'
import SocialLogin from '../components/shared/SocialLogin'
import { loginAPI } from '../API/authAPI'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { login } from '../redux/loginLogout'
import { getPaticularUserData } from '../API/UserAPI'

const Login = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    email: location?.state?.email || '',
    password: ''
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Incorrect email format.').required('Required!'),
    password: Yup.string()
      .required('Required!')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,16}$/,
        'Password must contain at least one letter, one number and one special character'
      )
  })

  const onSubmit = async (values) => {
    try {
      const res = await loginAPI({ ...values })
      const { data } = res

      Cookies.set('pms-refresh-token', data.refreshToken, {
        expires: 1
      })

      Cookies.set('user-email', values.email, {
        expires: 1
      })
      Cookies.set('user-status', true, {
        expires: 12 / 24
      })

      Cookies.set('pms-access-token', data.accessToken, {
        expires: 1 / 24
      })

      Cookies.set('check-refresh-token', data.refreshTokenCheck, {
        expires: 12 / 24
      })
      Cookies.set('check-access-token', data.accessTokenCheck, {
        expires: 1 / 24
      })

      dispatch(login({ token: data.accessToken }))

      getPaticularUserDataAPi()
    } catch (error) {
      messageApi.error('Unable to login.')
    }
  }

  const getPaticularUserDataAPi = async () => {
    try {
      const res = await getPaticularUserData()

      // handled the route scenerio
      if (res === null) {
        navigate('/auth/company')
      }

      const { data } = res

      Cookies.set('companyDetailsId', data?.companyDetailsId, {
        expires: 12 / 24
      })

      Cookies.set('companyDetails', JSON.stringify(data || {}), {
        expires: 12 / 24
      })

      let refreshtoken = Cookies.get('check-refresh-token')
        ? Cookies.get('check-refresh-token')
        : ''
      let accesstoken = Cookies.get('check-access-token')
        ? Cookies.get('check-access-token')
        : ''
      let email = Cookies.get('user-email') ? Cookies.get('user-email') : ''

      let pmsAccesstkn = Cookies.get('pms-access-token')
      let pmsRefreshToken = Cookies.get('pms-refresh-token')

      window.open(
        `https://synccoscheck.com?tkn=${accesstoken}&cmpId=${data?.companyDetailsId}&mail=${email}&rfTkn=${refreshtoken}&pmsTkn=${pmsAccesstkn}&pmsRfTkn=${pmsRefreshToken}`,
        '_blank'
      )
    } catch (error) {
      messageApi.error('Unable to login. Please try again.')
    }
  }

  return (
    <>
      {contextHolder}
      <div className='auth-form-div'>
        <FormHeader
          text='One stop solution for all your needs'
          head='Sign In'
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isValid }) => {
            return (
              <Form>
                <FormComponent
                  control='input'
                  name='email'
                  label={
                    <>
                      E-mail
                      <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Enter your Email ID'
                  type='email'
                  disabled={location?.state?.email}
                />
                <FormComponent
                  control='input'
                  name='password'
                  label={
                    <>
                      Password <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Enter your Password'
                  type='password'
                />
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <Checkbox className='generic-checkbox' />
                    <p className='remember-me-txt fs-14 mb-0 ms-1'>
                      Remember Me
                    </p>
                  </div>
                  <Link
                    to='/auth/forget-password'
                    className='fs-14 text-decoration-none'
                  >
                    Forget Password?
                  </Link>
                </div>
                <ButtonComponent
                  type='submit'
                  text='Sign In'
                  extraclassName='mt-5 w-100'
                  variant='dark'
                  click={handleSubmit}
                  disabled={!isValid}
                />
              </Form>
            )
          }}
        </Formik>

        <div className='divider mt-5 mb-5 d-flex align-items-center justify-content-center'>
          <span className='line'></span>
          <span className='txt'>Or Sign in with</span>
          <span className='line'></span>
        </div>

        <SocialLogin text='in' />

        <p className='redirect-txt text-center mt-2'>
          Don’t have an account ? <Link to='/auth/signup'>Sign Up</Link>
        </p>
      </div>
    </>
  )
}

export default Login
