import React, { useState } from 'react'
import { message } from 'antd'
import FormHeader from '../components/shared/forms/FormHeader'
import { Form, Formik } from 'formik'
import FormComponent from '../components/shared/forms'
import ButtonComponent from '../components/shared/ButtonComponent'
import jiraLogo from '../assets/images/jira-logo.png'
import quickbooks from '../assets/images/quickbooks-logo.png'
import { sendCompanyDetails, updateUserData } from '../API/UserAPI'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import Cookies from 'js-cookie'
import { verifyIfUserExists } from '../API/authAPI'

const Company = () => {
  const [messageApi, contextHolder] = message.useMessage()

  const [selectedRole, setSelectedRole] = useState('')
  const [selectedIndustry, setSelectedIndustry] = useState('')
  const [selectedCompanySize, setSelectedCompanySize] = useState('')
  const [selectedTools, setSelectedTools] = useState([])

  const navigate = useNavigate()

  const initialValues = {
    role: '',
    companyIndustry: '',
    companySize: '',
    toolsUsed: [],
    name: '',
    otherCompanyIndustry: ''
  }

  const validationSchema = Yup.object({
    role: Yup.string().required('Role is required'),
    companyIndustry: Yup.string().required('Company Industry is required'),
    companySize: Yup.string().required('Company Size is required'),
    toolsUsed: Yup.array(),
    name: Yup.string().required('Role is required')
    // otherRole: Yup.string().required('Other role is required'),
    // otherCompanyIndustry: Yup.string().required('Other company is required')
  })

  const roleOptions = [
    {
      value: 'CEO / Business Owner',
      label: 'CEO / Business Owner'
    },
    {
      value: 'Finance',
      label: 'Finance'
    },
    {
      value: 'IT & Engineering',
      label: 'IT & Engineering'
    },
    {
      value: 'Marketing',
      label: 'Marketing'
    },
    {
      value: 'Customer Service',
      label: 'Customer Service'
    },
    {
      value: 'Sales',
      label: 'Sales'
    },
    {
      value: 'Product Management',
      label: 'Product Management'
    },
    {
      value: 'HR',
      label: 'HR'
    },
    {
      value: 'Product and Design',
      label: 'Product and Design'
    },
    {
      value: 'Accounts',
      label: 'Accounts'
    },
    {
      value: 'Client Relationship',
      label: 'Client Relationship'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const industryOptions = [
    {
      value: 'IT Industry',
      label: 'IT Industry'
    },
    {
      value: 'Fintech',
      label: 'Fintech'
    },
    {
      value: 'Oil and Natural gas',
      label: 'Oil and Natural gas'
    },
    {
      value: 'Real Estate',
      label: 'Real Estate'
    },
    {
      value: 'Healthcare',
      label: 'Healthcare'
    },
    {
      value: 'Automotive Industry',
      label: 'Automotive Industry'
    },
    {
      value: 'Others',
      label: 'Others'
    }
  ]

  const toolOption = [
    {
      id: 'Jira',
      name: 'Jira',
      avatarUrl: jiraLogo
    },
    {
      id: 'Quickbooks',
      name: 'Quickbooks',
      avatarUrl: quickbooks
    }
  ]

  const handleRoleSelectChange = (selected) => {
    setSelectedRole(selected)
  }

  const handleIndustrySelectChange = (selected) => {
    setSelectedIndustry(selected)
  }

  const handleToolSelectChange = (selected) => {
    setSelectedTools(selected)
  }

  const handleCompanySizeSelect = (size, setFieldValue) => {
    setSelectedCompanySize(size) // Update selectedCompanySize state

    // Use setFieldValue to update companySize in Formik
    setFieldValue('companySize', size)
  }

  const searchUserId = async (formData) => {
    const email = Cookies.get('user-email') ?? ''
    try {
      const res = await verifyIfUserExists(email)
      const { data } = res

      let obj = { ...formData }
      obj['adminId'] = data?.id

      handleFormSubmit(obj, data?.id)
    } catch (error) {
      console.log('error in user search', error)
    }
  }
  const updateUserApi = async (companyId, userId) => {
    try {
      const { data } = await updateUserData({
        id: userId,
        companyDetailsId: companyId
      })
      Cookies.set('companyDetails', JSON.stringify(data || {}), {
        expires: 1 / 24
      })

      let refreshtoken = Cookies.get('check-refresh-token')
        ? Cookies.get('check-refresh-token')
        : ''
      let accesstoken = Cookies.get('check-access-token')
        ? Cookies.get('check-access-token')
        : ''
      let email = Cookies.get('user-email') ? Cookies.get('user-email') : ''

      let pmsAccesstkn = Cookies.get('pms-access-token')
      let pmsRefreshToken = Cookies.get('pms-refresh-token')

      window.open(
        `https://synccoscheck.com?tkn=${accesstoken}&cmpId=${companyId}&mail=${email}&rfTkn=${refreshtoken}&pmsTkn=${pmsAccesstkn}&pmsRfTkn=${pmsRefreshToken}`,
        '_self'
      )
    } catch (error) {
      console.log('error in update user', error)
    }
  }

  const handleFormSubmit = async (values, userId) => {
    try {
      const { data } = await sendCompanyDetails(values)
      // handle to submit the company details
      Cookies.set('companyDetailsId', data?.id)
      // Cookies.set('companyDetails', JSON.stringify(data || {}), {
      // 	expires: 1 / 24
      // })
      // Cookies.set('companyDetailsHeader', JSON.stringify(data || {}), {
      // 	expires: 1 / 24
      // })
      updateUserApi(data?.id, userId)
      messageApi.success('Company Details saved successfully!')
      Cookies.set('user-status', true, {
        expires: 12 / 24
      })
    } catch (error) {
      messageApi.error('Something went wrong')
    }
  }

  return (
    <>
      {contextHolder}
      <div className='auth-form-div'>
        <FormHeader
          text='Getting insights into your company enhances our ability to assist you effectively'
          head='What is your company like?'
        />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const body = { ...values }
            let toolsUsed
            if (Array.isArray(body?.toolsUsed)) {
              toolsUsed = body?.toolsUsed?.join(',')
              body.toolsUsed = toolsUsed
              //  for searching the userID ==> pending
              let userId = Cookies.get('userId')
              searchUserId(body)
            }
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form>
                <FormComponent
                  control='input'
                  name='name'
                  label='Company name'
                  placeholder='Enter Your Company Name'
                  setFieldValue={setFieldValue}
                />
                <FormComponent
                  control='generic-select'
                  name='role'
                  extraName='role'
                  label={<>What is your role at work?</>}
                  required
                  placeholder='Select your industry role'
                  type='text'
                  options={roleOptions}
                  selectedValues={selectedRole}
                  onSelectChange={handleRoleSelectChange}
                  setFieldValue={setFieldValue}
                />

                {values?.role === 'Others' ? (
                  <FormComponent
                    control='input'
                    name='otherRole'
                    required
                    placeholder={`Type in the role`}
                    type='text'
                    setFieldValue={setFieldValue}
                  />
                ) : null}

                <FormComponent
                  control='generic-select'
                  name='companyIndustry'
                  extraName='industry'
                  label={
                    <>
                      What industry is your company in?
                      <span className='required-field-star'>*</span>
                    </>
                  }
                  required
                  placeholder='Select your industry for'
                  type='text'
                  options={industryOptions}
                  selectedValues={selectedIndustry}
                  setFieldValue={setFieldValue}
                  onSelectChange={handleIndustrySelectChange}
                />

                {values?.companyIndustry === 'Others' ? (
                  <FormComponent
                    control='input'
                    name='otherCompanyIndustry'
                    required
                    placeholder={`Type in the industry`}
                    type='text'
                    setFieldValue={setFieldValue}
                  />
                ) : null}

                <div className='mb-4'>
                  <div className=' mb-3'>
                    <p
                      style={{
                        color: '#434343',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontWeight: 600
                      }}
                    >
                      How big is your company?
                      <span className='span-required-field-star'>*</span>
                    </p>
                  </div>

                  <div className=''>
                    <div className='d-flex fs-12 gap-3 mb-3'>
                      <div
                        className={`company-size ${
                          selectedCompanySize === 'Just me' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('Just me', setFieldValue)
                        }
                      >
                        Just me
                      </div>
                      <div
                        className={`company-size ${
                          selectedCompanySize === '2-10' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('2-10', setFieldValue)
                        }
                      >
                        2-10
                      </div>
                      <div
                        className={`company-size ${
                          selectedCompanySize === '11-20' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('11-20', setFieldValue)
                        }
                      >
                        11-20
                      </div>
                      <div
                        className={`company-size ${
                          selectedCompanySize === '21-50' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('21-50', setFieldValue)
                        }
                      >
                        21-50
                      </div>
                    </div>

                    <div className='d-flex fs-12 gap-3'>
                      <div
                        className={`company-size ${
                          selectedCompanySize === '51-100' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('51-100', setFieldValue)
                        }
                      >
                        51-100
                      </div>
                      <div
                        className={`company-size ${
                          selectedCompanySize === '101-500' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('101-500', setFieldValue)
                        }
                      >
                        101-500
                      </div>
                      <div
                        className={`company-size ${
                          selectedCompanySize === '500+' ? 'selected' : ''
                        }`}
                        onClick={() =>
                          handleCompanySizeSelect('500+', setFieldValue)
                        }
                      >
                        500+
                      </div>
                    </div>
                  </div>
                </div>

                <FormComponent
                  control='select'
                  name='toolsUsed'
                  label={<>Select your tools (optional)</>}
                  required
                  placeholder='Select your industry'
                  extraName='tools'
                  type='text'
                  multiple={true}
                  options={toolOption}
                  selectedValues={selectedTools}
                  onSelectChange={handleToolSelectChange}
                  // setFieldValue={setFieldValue}
                />

                <ButtonComponent
                  type='submit'
                  text='Finish'
                  extraclassName='mt-2 mb-3 w-100'
                  variant='dark'
                  click={handleSubmit}
                  // disabled={handleSubmit}
                />
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default Company
