import { AutoComplete, Space, Input } from 'antd'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import { useState } from 'react'
import expandMoreIcon from '../../../assets/images/expand-more-icon.svg'
import TextError from '../../../hoc/TextError'

const AutocompleteComponent = ({
  name,
  id,
  label,
  options,
  isAddBtn,
  disabled,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState()
  return (
    <div className='formField'>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <AutoComplete
              name={name}
              id={id}
              {...rest}
              options={options}
              style={{ width: '100%' }}
              // onSelect={(value) => { console.log(value);
              // 	// setFieldValue(value);
              // }}
              // onSearch={handleSearch}
              value={selectedOption}
              labelInValue
              onChange={(e) => {
                setFieldValue(name, e?.value)
                setSelectedOption(e?.label)
              }}
              // dropdownVisible={isDropdownVisible}
              // onDropdownVisibleChange={handleDropdownVisibleChange}
            >
              <Input
                suffix={
                  <Space>
                    <img src={expandMoreIcon} alt='expand-more-icon' />
                  </Space>
                }
              />
            </AutoComplete>
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default AutocompleteComponent
