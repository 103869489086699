import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormHeader from '../components/shared/forms/FormHeader'
import FormComponent from '../components/shared/forms'
import { Checkbox, message, Spin } from 'antd'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import ButtonComponent from '../components/shared/ButtonComponent'
import SocialLogin from '../components/shared/SocialLogin'
import { sendOTP } from '../API/UserAPI'
import { createMember } from '../API/MembersAPI'
import { signUpAPI, verifyIfUserExists } from '../API/authAPI'
import Cookies from 'js-cookie'
import { splitName } from '../utils/helper'

const SignUpWithInvite = () => {
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  const [isLoading, setIsLoading] = useState(true)
  const [isFailed, setIsFailed] = useState(false)

  const [data, setData] = useState({})

  const checkVerify = async (data) => {
    try {
      let isExistingUser = await verifyIfUserExists(data?.email)

      if (isExistingUser?.data?.hasOwnProperty('email')) {
        navigate('/auth/login', {
          state: { email: isExistingUser?.data?.email }
        })
      }
    } catch (error) {}
  }

  const checkValidity = async () => {
    try {
      const res = await createMember(token)

      const { data = {} } = res

      Cookies.set('companyDetailsId', data?.companyDetails?.id)
      Cookies.set('companyDetails', JSON.stringify(res?.data || {}), {
        expires: 12 / 24
      })

      checkVerify(data)
      setData(data)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setIsFailed(true)
    }
  }

  useEffect(() => {
    !token || token === '' ? navigate(`/auth/signup`) : checkValidity()
  }, [])

  const initialValues = {
    name: '',
    // lastName: '',
    email: data?.email || '',
    password: '',
    confirmPassword: '',
    //phone: '',
    isAggred: false
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Required!'),
    // lastName: Yup.string().required('Required!'),
    email: Yup.string().email('Incorrect Email format').required('Required!'),
    password: Yup.string()
      .required('Required!')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,16}$/,
        'Password must contain at least one letter, one number and one special character'
      ),
    confirmPassword: Yup.string()
      .required('Required')
      .min(8, 'Password must be at least 8 characters')
      .max(16, 'Password must be less than or equal to 16 characters')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^])[A-Za-z\d@$!%*#?&^]{8,16}$/,
        'Password must contain at least one letter, one number and one special character'
      ),
    isAggred: Yup.boolean()
  })

  const onSubmit = async (values) => {
    let body = { ...values }
    delete body.isAggred
    let companyDetailsId = Cookies.get('companyDetailsId')
    body['companyDetailsId'] = companyDetailsId

    const { firstName, lastName } = splitName(values?.name)

    body.firstName = firstName
    body.lastName = lastName

    delete body?.name

    try {
      const res = await signUpAPI(body)
      const { data } = res

      Cookies.set('pms-access-token', data?.accessToken, {
        expires: 1 / 24
      })

      Cookies.set('pms-refresh-token', data?.refreshToken, {
        expires: 1
      })

      Cookies.set('user-email', body?.email, {
        expires: 1 / 24
      })
      Cookies.set('user-status', true, {
        expires: 1 / 24
      })

      // debugger
      // messageApi.success('OTP sent successfully!')
      // navigate('/auth/verify-otp', {
      // 	state: {
      // 		values: { ...values },
      // 		invite: token ? true : false
      // 	}
      // })
      // debugger
      navigate('/dashboard/my-checks/all-checks')
      // debugger

      // debugger
    } catch (error) {
      messageApi.error('Not able to Sign-Up!')
    }
  }

  return (
    <>
      {contextHolder}
      {isLoading ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '100%' }}
        >
          <Spin size='large' />
        </div>
      ) : isFailed ? (
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '100%' }}
        >
          <h1 className='font-roboto' style={{ color: '#555555' }}>
            Invite link is expired!
          </h1>
        </div>
      ) : (
        <div className='auth-form-div'>
          <FormHeader
            text='All your needs met in one convenient place.'
            head={
              data?.companyDetails?.name
                ? `You've been invited by ${data?.companyDetails?.name} to use Synccos`
                : `You've been invited to use Synccos`
            }
          />
          {/* <SocialLogin /> */}
          {/* <div className="divider mt-4 mb-4 d-flex align-items-center justify-content-center">
					<span className="line"></span>
					<span className="txt">OR</span>
					<span className="line"></span>
				</div> */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit }) => {
              const { isAggred } = values
              return (
                <Form>
                  <FormComponent
                    control='input'
                    name='name'
                    label={
                      <>
                        Name <span className='required-field-star'>*</span>
                      </>
                    }
                    required
                    placeholder='Enter your Name'
                    type='text'
                  />
                  {/* <FormComponent
									control="input"
									name="lastName"
									label={
										<>
											Last Name <span className="required-field-star">*</span>
										</>
									}
									required
									placeholder="Last Name"
									type="text"
								/> */}
                  <FormComponent
                    control='input'
                    name='email'
                    value={data?.email}
                    label={
                      <>
                        E-Mail <span className='required-field-star'>*</span>
                      </>
                    }
                    required
                    //placeholder="Enter your Email ID"
                    type='email'
                    disabled
                  />
                  <FormComponent
                    control='input'
                    name='password'
                    label={
                      <>
                        Password <span className='required-field-star'>*</span>
                      </>
                    }
                    required
                    placeholder='Enter your Password'
                    type='password'
                  />
                  <FormComponent
                    control='input'
                    name='confirmPassword'
                    label={
                      <>
                        Confirm Password{' '}
                        <span className='required-field-star'>*</span>
                      </>
                    }
                    required
                    placeholder='Re-Enter your Password'
                    type='password'
                  />
                  {/* <FormComponent
									name="phone"
									label={
										<>
											Phone Number{' '}
											<span className="required-field-star">*</span>
										</>
									}
									control="phone-input"
									type="text"
									country="us"
									onChange={(phoneNumber) =>
										setFieldValue('phone', phoneNumber)
									}
								/> */}

                  <div className='aggree-div d-flex align-items-center fs-12'>
                    <Checkbox
                      className='generic-checkbox me-2 mb-4'
                      name='isAggred'
                      onChange={(e) => {
                        setFieldValue('isAggred', e.target.checked)
                      }}
                      checked={values.isAggred}
                      required
                    />
                    <p style={{ color: '#9797A6', fontWeight: 400 }}>
                      By signing up you acknowledge that you have read and agree
                      to our{' '}
                      <span style={{ color: '#555555', fonteight: 500 }}>
                        Terms of Service
                      </span>{' '}
                      and our{' '}
                      <span style={{ color: '#555555', fonteight: 500 }}>
                        Privacy Policy
                      </span>{' '}
                    </p>

                    {/* <Link to="/terms" className="text-decoration-none">
										Terms and Conditions
									</Link> */}
                  </div>
                  <ButtonComponent
                    type='submit'
                    text='Sign Up'
                    variant='dark'
                    extraclassName='mt-4 mb-0 btn-color w-100'
                    disabled={!isAggred}
                    click={handleSubmit}
                  />
                </Form>
              )
            }}
          </Formik>
          <div className='divider mt-4 mb-4 d-flex align-items-center justify-content-center'>
            <span className='line'></span>
            <span className='txt'>Or Sign up with</span>
            <span className='line'></span>
          </div>

          <SocialLogin text='up' />
          <p className='redirect-txt text-center mt-3'>
            Already have an account ? <Link to='/auth/login'>Sign In</Link>
          </p>
        </div>
      )}
    </>
  )
}

export default SignUpWithInvite
