export const splitName = (username) => {
  if (username !== null) {
    let regexPattern = /\s+/g
    let ans = username.replace(regexPattern, ' ')
    const [firstName, ...rest] = ans?.split(' ')
    const lastName = rest?.join(' ')

    return { firstName, lastName }
  }
}
