import { Checkbox } from 'antd'
import { Field } from 'formik'
import { useState } from 'react'

const CheckboxComponent = (name, id, label, options, ...rest) => {
  return (
    <div className='formField'>
      <Field name={name}>
        {({ form, field }) => {
          console.log('values are ' + form + field)
          const { setFieldValue } = form
          const { value } = field
          return (
            <Checkbox.Group
              name={name}
              id={id}
              options={options}
              defaultValue={['CSV']}
              onChange={(e) => setFieldValue(name, e)}
            />
          )
        }}
      </Field>
    </div>
  )
}

export default CheckboxComponent
