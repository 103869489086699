import React from 'react'
import appLogo from '../../assets/images/app-logo-light.png'
import { Link, useNavigate } from 'react-router-dom'
import { campaignIcon } from '../../assets/svg'

import './Layout.css'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'

const Header = () => {
  const navigate = useNavigate()
  let isLoggedIn = Cookies.get('user-status') ? true : false

  return (
    <header className='app-header'>
      <div className='container'>
        <div className='d-flex align-items-center justify-content-between py-5'>
          <div className='app-logo'>
            <img src={appLogo} alt='app-logo' />
          </div>
          <div className='header-nav'>
            <nav className='d-flex align-items-center'>
              <Link
                to='/'
                className='mx-5 fw-5 fw-semibold position-relative d-block header-nav-links'
              >
                Solutions
              </Link>
              <Link
                to='/'
                className='mx-5 fw-5 fw-semibold position-relative d-block header-nav-links'
              >
                Roadmap
              </Link>
              <Link
                to='/'
                className='mx-5 fw-5 fw-semibold position-relative d-block header-nav-links'
              >
                Blog
              </Link>
            </nav>
          </div>
          <div className='functional-section d-flex align-items-center justify-content-center'>
            <div className='header-icon me-5 position-relative notification-icon'>
              {campaignIcon}
              <span className='indicator'>
                <span className='indicate'>&nbsp;</span>
                <span className='indicate-border'>&nbsp;</span>
                <span className='indicate-border indicate-border-1'>
                  &nbsp;
                </span>
                <span className='indicate-border indicate-border-2'>
                  &nbsp;
                </span>
                <span className='indicate-border indicate-border-3'>
                  &nbsp;
                </span>
                <span className='indicate-border indicate-border-4'>
                  &nbsp;
                </span>
              </span>
            </div>
            <>
              <button
                className='common-btn white-btn me-3 fs-14 font-poppins'
                onClick={() => {
                  navigate('/auth/login')
                }}
              >
                Log in
              </button>
              <button
                className='common-btn blue-btn animation-right text-wrap fw-bold fs-6'
                onClick={() => {
                  navigate('/auth/signup')
                }}
              >
                Sign Up
                <span className='icon ms-3'>
                  <i className='fa-solid fa-arrow-right-to-bracket'></i>
                </span>
              </button>
            </>
            {/* {isLoggedIn ? (
              <>
                <button
                  className="common-btn blue-btn animation-right text-uppercase fw-bold fs-6"
                  onClick={() => {
                    let refreshtkn = Cookies.get("check-refresh-token")
                      ? Cookies.get("check-refresh-token")
                      : "";
                    let companyId = Cookies.get("companyDetailsId")
                      ? Cookies.get("companyDetailsId")
                      : "";
                    let accesstkn = Cookies.get("check-access-token")
                      ? Cookies.get("check-access-token")
                      : "";

                    let email = Cookies.get("user-email")
                      ? Cookies.get("user-email")
                      : "";
                    window.open(
                      `https://checkwriter-dev.synccos.com?tkn=${accesstkn}&cmpId=${companyId}&mail=${email}&rfTkn=${refreshtkn}`,
                      "_self",
                    );
                  }}
                >
                  To dashboard
                  <span className="icon ms-3">
                    <i className="fa-solid fa-arrow-right-to-bracket"></i>
                  </span>
                </button>
              </>
            ) : (
              
            )} */}
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
