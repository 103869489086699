import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { DatePicker } from 'antd'
import TextError from '../../../hoc/TextError'

const DatePickerComponent = (props) => {
  const { label, name, type, formfieldClass = '', ...rest } = props
  return (
    <div className={`formField ${formfieldClass}`}>
      <label htmlFor={`${name}`} className='d-block'>
        {label}
      </label>
      <Field name={name} id={name}>
        {({ field, form }) => {
          const { setFieldValue } = form
          return (
            <DatePicker
              name={name}
              value={field?.value}
              {...rest}
              format={'MM-DD-YYYY'}
              onChange={(e) => {
                setFieldValue(name, e)
                if (rest.onDateChange) {
                  rest.onDateChange(e)
                }
              }}
              className='d-block generic-date-picker'
            />
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default DatePickerComponent
