import AutocompleteComponent from './AutocompleteComponent'
import DatePickerInput from './DatePickerInput'
import InputNumberComponent from './InputNumberComponent'
import SelectComponent from './SelectComponent'
import CheckboxComponent from './CheckboxComponent'
import InputComponent from './InputComponent'
import GenericSelect from './GenericSelect'
import DatePickerComponent from './DatePickerComponent'
import PhoneNumberInput from './PhoneNumberInput'

const FormComponents = (props) => {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <InputComponent {...rest} />
    case 'input-number':
      return <InputNumberComponent {...rest} />
    case 'autocomplete':
      return <AutocompleteComponent {...rest} />
    case 'date':
      return <DatePickerInput {...rest} />
    case 'select':
      return <SelectComponent {...rest} />
    case 'checkbox':
      return <CheckboxComponent {...rest} />
    case 'generic-select':
      return <GenericSelect {...rest} />
    case 'date-picker':
      return <DatePickerComponent {...rest} />
    case 'phone-input':
      return <PhoneNumberInput {...rest} />
    default:
      return
  }
}

export default FormComponents
