import { Field, ErrorMessage } from 'formik'
import { DatePicker } from 'antd'
import TextError from '../../../hoc/TextError'
import forms from './forms.css'

const DatePickerInput = (props) => {
  const { name, label, ...rest } = props

  return (
    <div className='formField'>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <DatePicker
              {...rest}
              id={name}
              value={field.value}
              onChange={(date) => {
                console.log('new date set to ', date)
                setFieldValue(name, date)
              }}
              format={'MM-DD-YYYY'}
              label={label}
            />
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default DatePickerInput
