import React from 'react'

import appLogo from '../../assets/images/app-logo-light.png'
import { locationMarker, mailIcon, phoneIcon } from '../../assets/svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='app-footer mt-5'>
      <div className='container-fluid p-0 m-0'>
        <div className='container'>
          <div className='footer-upper'>
            <div className='row'>
              <div className='col-md-6'>
                <h4 className='fs-2 fw-bold mb-3'>Contact information</h4>
                <p className=''>
                  Rhoncus ipsum dolor sit amet, consectetur <br /> adipiscing
                  elit. Fusce vitae vestibulum orci, sit.
                </p>
              </div>
              <div className='col-md-6'>
                <div className='social'>
                  <div className='d-flex align-items-center justify-content-start mb-2'>
                    <span className='icon'>{mailIcon}</span>
                    <a href='mailto:support@synccos.com'>support@synccos.com</a>
                  </div>
                  <div className='d-flex align-items-center justify-content-start mb-2'>
                    <span className='icon'>{phoneIcon}</span>
                    <a href='tel:+1-833-279-6226'>+1-833-279-6226</a>
                  </div>
                  <div className='d-flex align-items-center justify-content-start mb-2'>
                    <span className='icon'>{locationMarker}</span>
                    <p>1021 State Rt 32 Highland Mills, NY 10930</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-middle'>
            <div className='row'>
              <div className='col-lg-6'>
                <h4 className='fs-2 fw-bold mb-3'>Stay Connected</h4>
                <p>
                  Don’t miss the chance to check out powerful products that we
                  will
                  <br /> release soon, enter your email to sign up for our
                  newsletter.
                </p>
                <div className='input-group'>
                  <form className='needs-validation d-flex align-items-center justify-content-center'>
                    <div className='d-flex align-items-center justify-content-center has-validation'>
                      <input
                        placeholder='Enter your email'
                        type='email'
                        className='border-0 form-control'
                        value=''
                      />
                      <button
                        type='submit'
                        className='border-0 common-btn d-flex align-items-center justify-content-center'
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row'>
                  {/* <div className="col-lg-4">
                    <div className="footer-links">
                      <h5 className="link-head">Product</h5>
                      <a href="/" className="link">
                        Overview
                      </a>
                      <a href="/" className="link">
                        Features
                      </a>
                      <a href="/" className="link">
                        Pricing
                      </a>
                    </div>
                  </div> */}
                  <div className='col-lg-5'>
                    <div className='footer-links'>
                      <h5 className='link-head'>Social</h5>
                      <a
                        href='https://twitter.com/SynccosApp'
                        target='blank'
                        rel='no-referrer'
                        className='link'
                      >
                        Twitter
                      </a>
                      <a
                        href='https://www.linkedin.com/company/synccos-inc'
                        target='blank'
                        rel='no-referrer'
                        className='link'
                      >
                        LinkedIn
                      </a>
                      <a
                        href='https://www.facebook.com/profile.php?id=100090749470482'
                        target='blank'
                        rel='no-referrer'
                        className='link'
                      >
                        Facebook
                      </a>
                      <a
                        href='https://www.instagram.com/synccosapp/'
                        target='blank'
                        rel='no-referrer'
                        className='link'
                      >
                        Github
                      </a>
                    </div>
                  </div>
                  <div className='col-lg-5'>
                    <div className='footer-links'>
                      <h5 className='link-head'>Legal</h5>
                      <Link to='/terms' className='link'>
                        Terms
                      </Link>
                      <Link to='/privacy' className='link'>
                        Privacy
                      </Link>
                      {/* <a href="/" className="link">
                        Cookies
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-lower mt-5'>
            <div className='d-flex align-items-center justify-content-between'>
              <div className='app-logo'>
                <img src={appLogo} alt='app-logo' />
              </div>
              <p className='copyright-txt me-4'>
                Copyright: 2023 Syncoos. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
