import { useEffect } from 'react'
import { Avatar, FloatButton } from 'antd'
import { ArrowUpward } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import '../Home.css'

import cavalaryLogo from '../assets/images/calavary.jpg'
import zoomTechLogo from '../assets/images/zoom-tech.jpg'
import cofinanceLogo from '../assets/images/cofinance.jpg'
import choose1Img from '../assets/images/choose-1.png'
import choose2Img from '../assets/images/choose-2.png'
import choose3Img from '../assets/images/choose-3.png'
import feature1Img from '../assets/images/feature-1.png'
import feature2Img from '../assets/images/feature-2.png'
import feature3Img from '../assets/images/feature-3.png'
import feature4Img from '../assets/images/feature-4.png'
import banner1Img from '../assets/images/banner-1.png'
import banner2Img from '../assets/images/banner-2.png'
import banner3Img from '../assets/images/banner-3.png'
import customerImg from '../assets/images/customer-img.png'
import tryFreeImg from '../assets/images/try-free.png'
import { automationIcon, simplifiedIcon, walletIcon } from '../assets/svg'

// type AnimationName =
// 	| 'slide-in-fwd-bottom'
// 	| 'slide-in-fwd-left'
// 	| 'slide-in-fwd-top'
// 	| 'slide-in-fwd-right'

// type Animations = {
// 	[key in AnimationName]: string
// }

const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const animations = {
      'slide-in-fwd-bottom':
        'slide-in-fwd-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both',

      'slide-in-fwd-left':
        'slide-in-fwd-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both',
      'slide-in-fwd-top':
        'slide-in-fwd-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both',

      'slide-in-fwd-right':
        'slide-in-fwd-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both'
    }

    let topElm = document.getElementsByClassName('slide-in-fwd-top')
    let bottomElm = document.getElementsByClassName('slide-in-fwd-bottom')
    let leftElm = document.getElementsByClassName('slide-in-fwd-left')
    let rightElm = document.getElementsByClassName('slide-in-fwd-right')

    let elms = [...topElm, ...bottomElm, ...leftElm, ...rightElm]

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Do something when the element is visible in the viewport

            const targetElement = entry.target

            const animationType =
              targetElement.dataset.animation || 'slide-in-fwd-bottom'

            targetElement.style.webkitAnimation = animations[animationType]
            targetElement.style.animation = animations[animationType]

            // Unobserve the target element so that the callback is not called again
            observer.unobserve(entry.target)
          }
        })
      },
      {
        root: null, // Use the viewport as the root element
        threshold: 0.5, // Call the callback when at least 50% of the element is visible
        rootMargin: '0px' // No margin around the root element
      }
    )

    elms.forEach((elem) => {
      observer.observe(elem)
    })
  }, [])

  useEffect(() => {
    let elems = document.getElementsByClassName('fade-animation')

    const elemsArr = [...elems]

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const targetElement = entry.target
            // Do something when the element is visible in the viewport
            targetElement.style.opacity = '1'

            // Unobserve the target element so that the callback is not called again
            observer.unobserve(entry.target)
          }
        })
      },
      {
        root: null, // Use the viewport as the root element
        threshold: 0.5, // Call the callback when at least 50% of the element is visible
        rootMargin: '0px' // No margin around the root element
      }
    )

    elemsArr.length !== 0 &&
      elemsArr.forEach((elem) => {
        observer.observe(elem)
      })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='container-fluid m-0 p-0'>
        {/* Hero section */}

        <div className='hero-section my-5 py-5'>
          <div className='container position-relative'>
            <div className='row justify-content-center text-center'>
              <div className='col-12 col-md-6 '>
                <h1
                  className='primary-header fs-60 fst-normal mb-4 slide-in-fwd-bottom font-poppins'
                  data-animation='slide-in-fwd-bottom'
                >
                  <span
                    style={{
                      color: '#3571A7',
                      fontWeight: 800
                    }}
                  >
                    Smart{' '}
                  </span>
                  and{' '}
                  <span
                    style={{
                      color: '#3571A7',
                      fontWeight: 800
                    }}
                  >
                    Simple
                  </span>{' '}
                  <br />
                  checkwriter
                </h1>
                <p
                  className='common-txt fw-light fs-6 mb-5 slide-in-fwd-top'
                  data-animation='slide-in-fwd-top'
                >
                  Discover the Ultimate Check Writing Solution: Synccos
                  Checkwriter- The Smarter Way to Handle Your Financial
                  Transactions with Ease and Confidence!
                </p>
                <div
                  className=' slide-in-fwd-top text-center d-flex align-items-center justify-content-center'
                  data-animation='slide-in-fwd-top'
                >
                  <button
                    className='common-btn blue-btn animation-right  fw-normal fs-6 mb-3'
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/auth/login')
                    }}
                  >
                    Launch Synccos Checkwriter
                    <span className='icon ms-3'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </span>
                  </button>
                </div>
              </div>

              {/* <div className="col-12 col-md-6">
								<div
									className="d-flex align-items-center justify-content-center slide-in-fwd-right banner-img-div"
									data-animation="slide-in-fwd-right"
								>
									<img src={'heroImg'} alt="" className="banner-img" />
								</div>
							</div> */}
            </div>
          </div>
        </div>

        <div
          className='container-fluid  d-flex'
          style={
            {
              // overflow:"hidden",
              // zIndex:1
            }
          }
        >
          <div className='row g-2 justify-content-center text-center'>
            <div className='col-12 col-md-3 col-lg-2 '>
              <img
                src={banner1Img}
                alt='feature'
                className=''
                data-animation='slide-in-fwd-bottom'
                width={'100%'}
                style={{
                  marginTop: '-16rem'
                }}
              />
            </div>
            <div className='col-12 col-md-6 col-lg-8 '>
              <img
                src={banner2Img}
                alt='feature'
                className='slide-in-fwd-bottom'
                width={'100%'}
              />
            </div>
            <div className='col-12 col-md-3 col-lg-2 '>
              <img
                src={banner3Img}
                alt='feature'
                className=''
                width={'100%'}
                style={{
                  marginTop: '-16rem'
                }}
              />
            </div>
          </div>
        </div>
        {/* Wht Choose Us section */}
        <div
          className='choose-us-section mt-5 mb-5 pt-5 pb-5'
          id='why-choose-us'
        >
          <div className='container pt-5'>
            <div className='row m-0 p-0 align-items-center'>
              <div className='col-12 col-md-6'>
                <div
                  className='slide-in-fwd-left'
                  data-animation='slide-in-fwd-left'
                >
                  <h2 className='section-header fs-48 fw-bold text-left'>
                    Why choose Synccos Checkwriter?
                  </h2>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div
                  className='slide-in-fwd-right'
                  data-animation='slide-in-fwd-right'
                >
                  <p className='common-txt fw-normal font-poppins ms-4'>
                    Choose Synccos CheckWriter for a Seamless and Secure
                    Financial Experience. Your Financial Control, Simplified
                  </p>
                </div>
              </div>
            </div>
            <div className='row m-0 p-0 mt-5'>
              <div className='col-12 col-md-4'>
                <div className='choose-div'>
                  <div className='icon'>{simplifiedIcon}</div>
                  <h4 className='fw-semibold font-poppins mb-1 text-black'>
                    Simplified Checks
                  </h4>
                  <p className='common-txt fs-6 fw-normal m-0 ms-4'>
                    Effortless Financial Management at Your Fingertips.
                    Simplify, Organize, and Succeed
                  </p>
                  <img src={choose1Img} alt='choose-img' />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='choose-div'>
                  <div className='icon'>{automationIcon}</div>
                  <h4 className='fw-semibold font-poppins mb-1 text-black'>
                    Automation and Integration
                  </h4>
                  <p className='common-txt fs-6 fw-normal'>
                    Streamlining Your Operations and Boosting Efficiency.
                    Seamlessly Connect, Simplify, and Thrive
                  </p>
                  <img src={choose2Img} alt='choose-img' />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='choose-div'>
                  <div className='icon'>{walletIcon}</div>
                  <h4 className='fw-semibold font-poppins mb-1 text-black'>
                    Time and Cost Savings
                  </h4>
                  <p className='common-txt fs-6 fw-normal'>
                    Maximize Efficiency, Minimize Expenses. Your Path to
                    Productivity and Profitability
                  </p>
                  <img src={choose3Img} alt='choose-img' />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features sections */}
        <div className='features-section pt-3 mb-5 pb-5' id='our-features'>
          <div className='container mt-5 pt-5'>
            <div className='row m-0 p-0 align-items-center'>
              <div className='col-12 col-md-6 p-0'>
                <div
                  className='slide-in-fwd-left'
                  data-animation='slide-in-fwd-left'
                >
                  <h2 className='section-header fs-48 fw-bold text-left'>
                    Our features
                  </h2>
                  <p className='common-txt fw-normal font-poppins ms-0 fs-6'>
                    Empowering Your Financial Management. Discover How We
                    Simplify and Enhance Your Check Writing Experience
                  </p>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div
                  className='slide-in-fwd-right'
                  data-animation='slide-in-fwd-right'
                >
                  <div className='d-flex align-items-center justify-content-end'>
                    <button
                      className='common-btn blue-btn animation-right text-uppercase fw-bold fs-6'
                      onClick={() => {
                        navigate('/auth/login')
                      }}
                    >
                      Launch Synccos Checkwriter
                      <span className='icon ms-3'>
                        <i className='fa-solid fa-arrow-right-to-bracket'></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-12 col-md-7 col-lg-8'>
                <div className='feature-div'>
                  <div>
                    <h3 className='fs-3 fw-semibold text-black font-poppins text-left'>
                      Create multiple checks
                    </h3>
                    <p className='common-txt fs-6 fw-normal mb-3'>
                      Easily Generate Multiple Checks with Our Advanced
                      Checkwriter Tool. Streamline Your Workflow, Boost
                      Efficiency, and Handle Large Volumes of Payments with
                      Confidence and Precision
                    </p>
                  </div>
                  <div className='feature-img-div'>
                    <img
                      src={feature1Img}
                      alt='feature'
                      className='feature-image'
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-5 col-lg-4'>
                <div className='feature-div'>
                  <div>
                    <h3 className='fs-3 fw-semibold text-black font-poppins text-left'>
                      Manage Tags and Signatures
                    </h3>
                    <p className='common-txt fs-6 fw-normal mb-3'>
                      Effortlessly Control and Customize Signatures for a
                      Professional and Streamlined Experience
                    </p>
                  </div>
                  <div className='feature-img-div'>
                    <img
                      src={feature2Img}
                      alt='feature'
                      className='feature-image'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-12 col-md-4'>
                <div className='feature-div'>
                  <div>
                    <h3 className='fs-3 fw-semibold text-black font-poppins text-left'>
                      Export checks customization
                    </h3>
                    <p className='common-txt fs-6 fw-normal mb-3'>
                      Unlock the Freedom to Customize and Export Checks to Suit
                      Your Unique Needs
                    </p>
                  </div>
                  <div className='feature-img-div'>
                    <img
                      src={feature3Img}
                      alt='feature'
                      className='feature-image'
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-8'>
                <div className='feature-div'>
                  <div>
                    <h3 className='fs-3 fw-semibold text-black font-poppins text-left'>
                      Import, share and mail checks
                    </h3>
                    <p className='common-txt fs-6 fw-normal mb-3'>
                      Easily Generate Multiple Checks with Our Advanced
                      Checkwriter Tool. Streamline Your Workflow, Boost
                      Efficiency, and Handle Large Volumes of Payments with
                      Confidence and Precision
                    </p>
                  </div>
                  <div className='feature-img-div'>
                    <img
                      src={feature4Img}
                      alt='feature'
                      className='feature-image'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Testimonial section */}
        <div className='testimonial-section mt-5 pt-5 mb-5'>
          <div className='container d-none'>
            <div className='row m-0 p-0 align-items-center'>
              <div className='col-12 col-md-6'>
                <div
                  className='slide-in-fwd-left'
                  data-animation='slide-in-fwd-left'
                >
                  <h2 className='section-header fs-48 fw-bold text-left'>
                    Hear from our customers
                  </h2>
                  <p className='common-txt fw-normal font-poppins ms-0'>
                    Listen to your customers and drive improvement with our
                    customer feedback tools
                  </p>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div
                  className='slide-in-fwd-right'
                  data-animation='slide-in-fwd-right'
                >
                  <div className='d-flex align-items-center justify-content-end'>
                    <button
                      className='common-btn white-btn animation-right text-uppercase fw-bold fs-6'
                      onClick={() => {
                        navigate('/auth/login')
                      }}
                      style={{
                        border: '1px solid #204464'
                      }}
                    >
                      Launch Synccos Checkwriter
                      <span className='icon ms-3'>
                        <i className='fa-solid fa-arrow-right-to-bracket'></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              id='carouselExampleIndicators'
              className='carousel mt-5 pt-3 carousel-fade d-none'
              data-bs-ride='carousel'
            >
              <div className='carousel-inner'>
                <div className='carousel-item active' data-bs-interval='3000'>
                  <div className='row testinomial-carousel-row'>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className='ms-3'>
                            <p className='fw-semibold fs-4 font-poppins text-black mb-0'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                        <p className='fs-6 common-txt font-poppins fw-normal mb-0'>
                          “Unlock the Full Potential of Your Product Team with
                          Our Cutting-Edge Product Management.Unlock the Full
                          Potential of Your Product Team with Our Cutting-Edge
                          Product Management Unlock the Full Potential of Your
                          Product Team with Our Cutting-Edge Product Management
                          “
                        </p>
                      </div>
                    </div>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className='ms-3'>
                            <p className='fw-semibold fs-4 font-poppins text-black mb-0'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                        <p className='fs-6 common-txt font-poppins fw-normal mb-0'>
                          “Unlock the Full Potential of Your Product Team with
                          Our Cutting-Edge Product Management.Unlock the Full
                          Potential of Your Product Team with Our Cutting-Edge
                          Product Management Unlock the Full Potential of Your
                          Product Team with Our Cutting-Edge Product Management
                          “
                        </p>
                      </div>
                    </div>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='carousel-item' data-bs-interval='3000'>
                  <div className='row testinomial-carousel-row'>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='carousel-item' data-bs-interval='3000'>
                  <div className='row testinomial-carousel-row'>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-md-4'>
                      <div className='customer-div'>
                        <div className='d-flex align-items-center'>
                          <div className='customer-img-div'>
                            <img src={customerImg} alt='' />
                          </div>
                          <div className=''>
                            <p className='fw-semibold fs-4 font-poppins text-black'>
                              Customer Name
                            </p>
                            <p className='fs-6 fw-normal font-poppins'>
                              @customername
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='carousel-controllers d-flex align-items-center justify-content-center'>
                {/* <button
								className="carousel-control-prev position-relative"
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide="prev"
							>
								<i className="fa-solid fa-chevron-left"></i>
							</button> */}
                <div className='carousel-indicators position-relative'>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators '
                    data-bs-slide-to='0'
                    className='active testimonial-carousel-indicator'
                    aria-current='true'
                    aria-label='Slide 1'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='1'
                    aria-label='Slide 2'
                    className='testimonial-carousel-indicator'
                  ></button>
                  <button
                    type='button'
                    data-bs-target='#carouselExampleIndicators'
                    data-bs-slide-to='2'
                    aria-label='Slide 3'
                    className='testimonial-carousel-indicator'
                  ></button>
                </div>
                {/* <button
								className="carousel-control-next position-relative"
								type="button"
								data-bs-target="#carouselExampleIndicators"
								data-bs-slide="next"
							>
								<i className="fa-solid fa-chevron-right"></i>
							</button> */}
              </div>
            </div>
          </div>
        </div>
        {/* Clients section */}
        <div className='our-clients-section mb-5 pb-5 d-none'>
          <div className='container'>
            <h2 className='section-header fs-48 fw-bold text-center'>
              Our Clients
            </h2>
            <p className='common-txt fw-normal text-center'>
              We have been working with some Fortune 500+ clients
            </p>
            <div className='row mb-5'>
              <div className='col-12 col-md-4'>
                <div className='client-logo'>
                  <img src={cavalaryLogo} alt='client-logo' />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='client-logo'>
                  <img src={zoomTechLogo} alt='client-logo' />
                </div>
              </div>
              <div className='col-12 col-md-4'>
                <div className='client-logo'>
                  <img src={cofinanceLogo} alt='client-logo' />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Lets start section */}
        <div className='start-section position-relative '>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 col-md-6 col-lg-4 offset-lg-2 '>
                <h2
                  className='section-header fs-48 fw-bold text-left mt-4'
                  data-animation='slide-in-fwd-bottom'
                >
                  What are you waiting for? Try it for free
                </h2>
                <p
                  className='common-txt fw-normal fs-5 mb-5 slide-in-fwd-top'
                  data-animation='slide-in-fwd-top'
                >
                  Why Wait? Experience It for Free Today! Discover the Benefits
                  and Ease of Our Checkwriter Tool – Sign Up Now!
                </p>
                <div
                  className='d-flex align-items-start flex-column slide-in-fwd-top'
                  data-animation='slide-in-fwd-top'
                >
                  <button
                    className='common-btn blue-btn animation-right text-uppercase fw-bold fs-6 mb-3'
                    onClick={(e) => {
                      e.preventDefault()
                      navigate('/auth/login')
                    }}
                  >
                    Launch Synccos Checkwriter
                    <span className='icon ms-3'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </span>
                  </button>
                </div>
              </div>
              <div className='col-12 col-md-6 position-relative w-full'>
                <div
                  className='d-flex align-items-center justify-content-center slide-in-fwd-right banner-img-div'
                  data-animation='slide-in-fwd-right'
                >
                  <img src={tryFreeImg} alt='' className='banner-img' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatButton
        onClick={() => {
          window.scrollTo(0, 0)
        }}
        className='homepage-float-btn'
        icon={
          <ArrowUpward
            sx={{
              fontSize: '1rem'
            }}
          />
        }
      />
    </>
  )
}

export default Home
