import React, { useState } from 'react'
import { Layout, Menu } from 'antd'
import { Outlet } from 'react-router'

import { cardHolderIcon } from '../../assets/svg'
import DashboardHeader from './DashboardHeader'
import {
  Dashboard,
  PeopleAlt,
  Star,
  FileCopy,
  Apps,
  Settings,
  PaymentsOutlined
} from '@mui/icons-material'
import { useNavigate } from 'react-router'

const { Sider, Content } = Layout

const DashboardLayout = () => {
  const [collapsed, setCollapsed] = useState(true)
  const [openKeys, setOpenKeys] = useState([])
  const navigate = useNavigate()

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label
    }
  }

  const topItems = [
    getItem('Dashboard', '1', <Dashboard />),
    getItem(
      'My Checks',
      '2',
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M6 7.5H14V6H6V7.5ZM6 14H12V12.5H6V14ZM6 10.75H14V9.25H6V10.75ZM4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H4.5Z'
          fill='#204464'
        />
      </svg>
    ),
    getItem('Payments', '3', <PaymentsOutlined />)
  ]

  const rootSubmenuKeys = ['sub1', 'sub2', 'sub3']

  // const bottomItems = [
  //   getItem("Apps", "6", <Apps />),
  //   getItem("Settings", "7", <Settings />),
  // ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const handleMenuClick = (e) => {
    switch (e.key) {
      case '2':
        navigate('/dashboard/my-checks/all-checks')
        break
      case '3':
        navigate('/dashboard/payments')
        break
      default:
        break
    }
  }

  return (
    <Layout className='app-main-container'>
      <DashboardHeader />
      <Layout className='app-inner-layout position-relative'>
        <div className={`sider-container ${collapsed ? '' : 'show'}`}>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onMouseOver={() => setCollapsed(false)}
            onMouseLeave={() => setCollapsed(true)}
            className='app-sider bg-white'
          >
            <div className='sidebar-menu'>
              <div className='sidebar-menu-top'>
                <Menu
                  className='sidebar-menu-top-item'
                  defaultSelectedKeys={['1']}
                  mode='inline'
                  items={topItems}
                  subMenuOpenDelay={10}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  onClick={handleMenuClick}
                />
              </div>

              {/* <div className="sidebar-menu-bottom">
                <Menu
                  className="sidebar-menu-bottom-item"
                  mode="inline"
                  items={bottomItems}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                />
              </div> */}
            </div>
          </Sider>
        </div>
        <Content className='app-content-container'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default DashboardLayout
