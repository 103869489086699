import { useState, useEffect } from 'react'
import ButtonComponent from '../../components/shared/ButtonComponent'
import importCheckIcon from '../../assets/images/import-check-icon.svg'
import deleteIcon from '../../assets/images/delete-icon.svg'
import { useNavigate } from 'react-router-dom'
import { DeleteFilled } from '@ant-design/icons'
import {
  saveFilesToLocalStorage,
  getFilesFromLocalStorage
} from '../LocalStorageUtils'
import { message, Upload, Button } from 'antd'
import keyboardArrowLeftIcon from '../../assets/images/keyboard-arrow-left-icon.svg'

const { Dragger } = Upload

const ImportUploadFiles = ({ entity }) => {
  const navigate = useNavigate()

  const [fileList, setFileList] = useState([])

  useEffect(() => {
    // Load the file list from local storage when the component mounts
    // const storedFileList = JSON.parse(localStorage.getItem('uploadedFiles')) || [];
    const storedFileList = getFilesFromLocalStorage()
    setFileList(storedFileList)
  }, [])

  useEffect(() => {
    // Save the uploaded files to localStorage whenever fileList changes
    console.log('Files to be saved to ls', fileList)
    saveFilesToLocalStorage(fileList)
  }, [fileList])

  console.log('File list is ', fileList)

  const customRequest = ({ file, onSuccess }) => {
    // Custom upload logic here, for example, using axios or fetch
    // Once the upload is complete, call onSuccess with any response data.
    setTimeout(() => {
      onSuccess('OK')
    }, 1000)
  }

  const handleRemove = (file) => {
    // Custom logic to remove a file from the fileList
    const updatedList = fileList.filter((item) => item.uid !== file.uid)
    setFileList(updatedList)
  }

  const props = {
    customRequest,
    fileList,
    showUploadList: true, // Show the custom list
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      console.log('file before upload', file)
      console.log('filelist before upload', fileList)
      return true
    }
  }
  return (
    <>
      <div className='d-flex flex-column'>
        <div className='d-flex mb-2'>
          <img
            src={keyboardArrowLeftIcon}
            alt='icon'
            onClick={() =>
              navigate('/dashboard/my-checks/import-checks/instructions')
            }
          />
          <span className='font-roboto fs-6 fw-bolder instructions'>
            Prepare and upload file
          </span>
        </div>
        <span className='font-roboto fs-14 instructions'>
          Organize your data using the sample files. For example. same headers,
          columns, and data types.
        </span>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <Dragger {...props}>
            <div className='d-flex flex-column justify-content-center align-items-center'>
              <img src={importCheckIcon} alt='icon' className='mt-5 mb-2' />
              <span className='font-roboto fw-bolder fs-4'>
                Drag and drop your files here
              </span>
              <span className='font-roboto fs-6 fw-normal upload-file-or my-2'>
                or
              </span>
              <span className='font-roboto fs-6 upload-file-option fw-bolder mb-2'>
                Choose from your system by
                <a style={{ color: '#4796DF' }}> clicking here</a>
              </span>
              <span className='font-roboto mb-4 fs-6 fw-normal upload-file-or'>
                Max file size : 20mb
              </span>
            </div>
          </Dragger>
          {fileList.map((file) => (
            <div
              key={file.uid}
              className='d-flex justify-content-between align-items-center upload-list-item my-3'
            >
              <div className='d-flex justify-content-center align-items-center'>
                <div
                  style={{
                    width: '46px',
                    height: '41px',
                    backgroundColor: '#F5F5F5'
                  }}
                ></div>
                <div className='d-flex flex-column ms-3'>
                  <span className='font-roboto fw-bolder fs-6 filename'>
                    {file.name}
                  </span>
                  <span className='font-roboto fw-normal fs-14 uploaded'>
                    Uploaded
                  </span>
                </div>
              </div>
              <br />
              <img
                src={deleteIcon}
                alt='icon'
                onClick={() => handleRemove(file)}
                className='delete-icon'
              />
            </div>
          ))}
        </div>
        <div className='d-flex justify-content-end align-items-center mt-5'>
          <ButtonComponent
            variant='light'
            text='Back'
            click={() =>
              navigate(`/dashboard/my-${entity}/import-checks/instructions`)
            }
          />
          <ButtonComponent
            variant='dark'
            text='Next'
            disabled={fileList.length === 0}
            click={() =>
              navigate(`/dashboard/my-${entity}/import-checks/validate-data`)
            }
          />
        </div>
      </div>
    </>
  )
}

export default ImportUploadFiles
