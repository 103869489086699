import React from 'react'

type FormHeaderProps = {
  text: String
  head: String
}

const FormHeader = (props: FormHeaderProps) => {
  const { text, head } = props
  return (
    <div className='form-head mb-5'>
      <h2 className='fw-semibold fs-3 mb-2 text-start form-header'>{head}</h2>
      <p className='form-headline fs-14'>{text}</p>
    </div>
  )
}

export default FormHeader
