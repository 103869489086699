import { InputNumber } from 'antd'
import { Field, useFormikContext, ErrorMessage } from 'formik'
import addCircleIconGrey from '../../../assets/images/add-circle-icon-grey.svg'
import removeCircleIcon from '../../../assets/images/remove-circle-icon.svg'
import TextError from '../../../hoc/TextError'

const InputNumberComponent = (props) => {
  const {
    label,
    name,
    type,
    formfieldClass = '',
    addBtn = true,
    ...rest
  } = props
  const { setFieldValue } = useFormikContext()

  const handleValueChange = (value) => {
    console.log('Typeof value is ', typeof value)
    setFieldValue(name, value)
  }

  return (
    <div className={`formField ${formfieldClass}`}>
      <Field name={name}>
        {({ form, field }) => {
          return (
            <div className='d-flex border border-1'>
              <InputNumber
                name={name}
                id={name}
                {...rest}
                value={field.value}
                step={1}
                onChange={handleValueChange}
                label={label}
                style={{
                  width: '100%'
                }}
                controls={false}
                className='num-checks-input'
                type={type}
              />
              {addBtn && (
                <div className='d-flex'>
                  <img
                    src={addCircleIconGrey}
                    className='me-2'
                    onClick={() => {
                      handleValueChange(field.value + 1)
                    }}
                    alt='add-circle-icon'
                  />
                  <img
                    src={removeCircleIcon}
                    className='me-2'
                    onClick={() => {
                      field.value > 0 && handleValueChange(field.value - 1)
                    }}
                    alt='remove-circle-icon'
                  />
                </div>
              )}
            </div>
          )
        }}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}

export default InputNumberComponent
